<template>
  <div class="container ma-tb-20 p-relative">
    <car-state ref="carState">
      <template v-slot:refresh>
        <div class="btns flex f-center c-fff" @click="loadData(false)">
          <div class="fon-20 iconfont icon-shuaxin" :class="{
            'refresh' : isRefresh
          }"></div>
          <div class="fon-18 font-bold">刷新<span class="ma-l-10" id="time"></span>s</div>
        </div>
      </template>
    </car-state>
    <div
      v-if="!$validator.isEmpty(hospitalState)"
      class="statebox c-red font-bold"
    >
      医院接诊状态：{{ hospitalState }}
    </div>
    <div class="flex f-between index-box">
      <div class="flex flex-direction f-between left-container">
        <div class="info btn bg-fff flex f-between">
          <div v-if="!detail.IsTask" class="flex f-center f-jcenter width100">
            <van-empty description="暂未参加任务" />
          </div>
          <div class="width80" v-if="detail.IsTask">
            <div class="flex f-center ma-t-10">
              <div class="titles ma-l-10 c-666 font-bold">报警信息</div>
            </div>
            <div class="ma-l-10 fon-16">
              <div class="ma-t flex f-center ">
                <div class="name">报警时间：</div>
                <div class="c-666">{{ detail.TaskInfo.AlarmTime }}</div>
              </div>
              <div class="ma-t flex f-center">
                <div class="name">报警人：</div>
                <div class="c-666">
                  {{ detail.TaskInfo.People }}&nbsp;&nbsp;{{
                    detail.TaskInfo.Phone
                  }}
                </div>
              </div>
              <div class="ma-t flex f-center">
                <div class="name">病人：</div>
                <div class="c-666">{{ detail.TaskInfo.PatientName }}</div>
              </div>
              <div class="ma-t flex f-center">
                <div class="name">事故地点：</div>
                <div class="c-666">{{ detail.TaskInfo.SceneAddress }}</div>
              </div>
              <div class="ma-t flex f-center">
                <div class="name">起因/症状：</div>
                <div class="c-666">{{ detail.TaskInfo.Symptom }}</div>
              </div>
              <div class="ma-t flex f-center">
                <div class="name">当前状态：</div>
                <div class="c-666">{{ detail.TaskInfo.Status }}</div>
              </div>
            </div>
          </div>
          <router-link
            :to="{ name: 'taskDetail', query: { id: detail.TaskInfo.TaskId } }"
            v-if="detail.IsTask"
          >
            <div class="btn-detail fon-18 flex f-center f-jcenter">详情 ></div>
          </router-link>
        </div>
        <div class="car-follower btn bg-fff flex f-between">
          <div class="titles ma-t-10 ma-l-10 c-666 font-bold">跟车人员</div>
          <div class="flex f-center f-jcenter member-box">
            <div class="flex f-center f-jcenter flex-direction pa-r-20">
              <van-image
                round
                width="60"
                height="60"
                fit="cover"
                :src="detail.Member.Driver.HeadImage"
              />
              <div class="name c-666 t-center">
                {{ detail.Member.Driver.Name }}（司机）
              </div>
            </div>
            <div class="flex f-center f-jcenter flex-direction pa-r-20">
              <van-image
                round
                width="60"
                height="60"
                fit="cover"
                :src="detail.Member.Doctor.HeadImage"
              />
              <div class="name c-666 t-center">
                {{ detail.Member.Doctor.Name }}（医生）
              </div>
            </div>
            <div class="flex f-center f-jcenter flex-direction pa-r-20">
              <van-image
                round
                width="60"
                height="60"
                fit="cover"
                :src="detail.Member.Nurse.HeadImage"
              />
              <div class="name c-666 t-center">
                {{ detail.Member.Nurse.Name }}（护士）
              </div>
            </div>
            <div
              class="flex f-center f-jcenter flex-direction pa-r-20"
              v-if="!$validator.isEmpty(detail.Member.Stretcher)"
            >
              <van-image
                round
                width="60"
                height="60"
                fit="cover"
                :src="detail.Member.Stretcher.HeadImage"
              />
              <div class="name c-666 t-center">
                {{ detail.Member.Stretcher.Name }}（担架员）
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-box flex flex-direction">
        
        <div class="flex">
          <!-- <div
            class="btn btn-DA6D58 flex f-center f-jcenter c-fff"
          >
            <div class="fon-20 iconfont icon-xingzhuang"></div>
            <div class="fon-16 font-bold">在线呼叫</div>
          </div> -->
          <div
            class="btn width100 flex f-center c-fff ma-b-20"
            :class="
              detail.TaskInfo.Status == '任务完成' ||
              !detail.IsTask ||
              detail.TaskInfo.Status.indexOf('取消') > -1
                ? 'btn-DA6D58'
                : 'btn-a2a2a2'
            "
            @click="
              detail.TaskInfo.Status == '任务完成' ||
              !detail.IsTask ||
              detail.TaskInfo.Status.indexOf('取消') > -1
                ? changeState(1, '待命')
                : ''
            "
          >
            <div class="fon-24 iconfont icon-ambulance-full"></div>
            <div class="fon-20 font-bold">待命</div>
          </div>
        </div>
        <div
          class="btn btn-EC8A4B width100 flex f-center c-fff ma-b-20"
          @click="showRemark"
        >
          <div class="fon-24 iconfont icon-ambulance-full"></div>
          <div class="fon-20 font-bold">空车返回</div>
        </div>

        <div
          class="btn width100 flex f-center c-fff ma-b-20"
          :class="
            $validator.isEmpty(detail.TaskInfo) ||
            !detail.IsTask ||
            detail.TaskInfo.Status == '任务完成' ||
            detail.TaskInfo.Status.indexOf('取消') > -1
              ? 'btn-EC8A4B'
              : 'btn-a2a2a2'
          "
          @click="
            $validator.isEmpty(detail.TaskInfo) ||
            !detail.IsTask ||
            detail.TaskInfo.Status == '任务完成' ||
            detail.TaskInfo.Status.indexOf('取消') > -1
              ? changeState(2, '休整')
              : ''
          "
        >
          <div class="fon-24 iconfont icon-zhengli"></div>
          <div class="fon-20 font-bold">休整</div>
        </div>
        <router-link to="/task/history">
          <div class="btn btn-8CBD66 flex f-center c-fff ma-b-20">
            <div class="fon-24 iconfont icon-lishi"></div>
            <div class="fon-20 font-bold">历史任务</div>
          </div>
        </router-link>
        <router-link to="setting">
          <div class="btn btn-60AAC4 flex f-center c-fff">
            <div class="fon-24 iconfont icon-tubiao01"></div>
            <div class="fon-20 font-bold">设置</div>
          </div>
        </router-link>
      </div>
    </div>
    <van-dialog
      v-model:show="isShow"
      show-cancel-button
      :before-close="beforeClose"
      title="提示"
    >
      <van-field
        size="large"
        v-model="remark"
        placeholder="请输入空车返回原因"
      />
    </van-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    return {
      hospitalState: "", //医院接诊状态
      detail: {
        TaskInfo: "",
        Member: {
          Driver: "",
          Doctor: "",
          Nurse: "",
          Stretcher: "",
        },
      },
      loadDataInterval: null,
      isShow: false,
      remark: "", //空车返回原因
      isRefresh: false, //是否刷新
      time: 0,
      dataInterval: null
    };
  },
  created() {
    _this = this;
    _this.loadData(true);
    _this.time = localStorage.getItem('refresh_time')
    if (!_this.$validator.isEmpty(_this.time) && _this.time != 0) {
      _this.loadDataInterval = setInterval(() => {
        _this.loadData(true);
      }, 1000 * _this.time);
    }
  },
  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    clearInterval(_this.loadDataInterval);
    clearInterval(_this.dataInterval)
    next();
  },
  methods: {
    count() {
      var times = localStorage.getItem('refresh_time')
      document.getElementById('time').innerHTML = times || 0
      if (times > 0) {
        _this.dataInterval = setInterval(() => {
          times --
          document.getElementById('time').innerHTML = times
        }, 1000 * 1);
      } else {
        clearInterval(_this.dataInterval)
      }
    },
    // 加载数据
    async loadData(refresh) {
      if (!refresh) {
        _this.isRefresh = true
      }
      clearInterval(_this.dataInterval)
      let _data = await _this.$request(
        "/Ambulance/v1/Index",
        {
          method: "POST",
          data: {},
        },
        false,
        refresh
      );
      if (_data.Code == 0) {
        _this.detail = _data.Data;
        if (_this.detail.IsTask) {
          //获取医院接诊状态
          _this.getHospitalState();
        }
        if (!refresh) {
          _this.isRefresh = false
        }
        _this.count()
      }
    },

    /**
     * 获取医院接诊状态
     */
    getHospitalState() {
      _this
        .$request(
          "/Task/v1/TaskTask/CheckReceptionStatus?taskId=" +
            _this.detail.TaskInfo.TaskId,
          {},
          false,
          true
        )
        .then((res) => {
          switch (res.Data.Status) {
            case 1:
              return (_this.hospitalState = "医院端在线");
            case 2:
              return (_this.hospitalState = "已做好接诊准备");
            default:
              return (_this.hospitalState = "");
          }
        });
    },

    // 展示空车返回弹框
    showRemark() {
      _this.isShow = true;
    },

    beforeClose(action) {
      if (action === "confirm") {
        _this.changeState(3, "空车返回");
      } else if (action === "cancel") {
        _this.isShow = false;
      }
    },

    // 切换状态
    changeState(status, name) {
      if (status == 3 && _this.$validator.isEmpty(_this.remark)) {
        _this.$toast("请输入空车返回的原因");
        return;
      }
      if (status != 3) {
        _this.$dialog
          .confirm({
            title: "提示",
            message: "确认" + name + "吗？",
          })
          .then(async () => {
            _this.loadStatus(status);
          })
          .catch(() => {
            // on cancel
          });
      } else {
        _this.loadStatus(status);
        _this.isShow = false;
      }
    },

    loadStatus(status) {
      _this
        .$request("/Ambulance/v1/UpdateStatus", {
          method: "POST",
          data: {
            TaskId: _this.taskId,
            Status: status,
            Remark: _this.remark,
          },
        })
        .then((res) => {
          if (res.Code == 0) {
            _this.$toast.success({
              message: "设置成功",
              duration: "1000",
              onClose() {
                _this.$refs.carState.loadData();
                _this.loadData(true);
              },
            });
          }
        });
    },
  },
};
</script>

<style lang="less">
.refresh{
  animation: mymove 2s linear infinite;
}
@keyframes mymove {
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(360deg)
	}
}

@-webkit-keyframes mymove

/*Safari and Chrome*/
	{
	from {
		transform: rotate(0deg)
	}

	to {
		transform: rotate(360deg)
	}
}

.statebox {
  position: absolute;
  right: 0;
  top: 0;
}
.p-relative {
  position: relative;
}
.index-box {
  height: 540px;
  margin-top: 12px;
  width: 100%;
  .left-container {
    width: 75%;

    .info {
      width: 100%;
      height: 390px !important;
      .btn-detail {
        background: #ffffff;
        box-shadow: 5px 10px 32px 0px rgba(42, 56, 91, 0.06);
        border-radius: 48px;
        border: 1px solid #eeeeee;
        color: #666666;
        margin: 8px 5px 0 0;
        padding: 6px 15px;
      }
      .ma-t {
        margin-top: 10px;
        font-size: 20px;
        .name {
          font-weight: bold;
          color: #666666;
          width: 140px;
          text-align: right;
          font-size: 16px;
        }
        .c-666 {
          font-size: 17px;
          width: 615px;
        }
      }
      .van-empty {
        padding: 0;
        .van-empty__image {
          width: 110px;
          height: 110px;
        }
      }
    }
    .car-follower {
      height: 120px;
      width: 100%;
      .follow-head {
        width: 30px;
        height: 30px;
      }
      .name {
        font-size: 15px;
        padding-top: 5px;
      }
    }
  }
  .titles {
    font-size: 20px;
    position: relative;
    padding-left: 8px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      width: 3px;
      height: 20px;
      background: #65bddc;
      box-shadow: 2px 4px 13px 0px rgba(42, 56, 91, 0.06);
      border-radius: 3px;
    }
  }
  .btn-box {
    width: 23%;
    .btn {
      height: 75px;
      box-shadow: 2px 4px 13px 0px rgba(42, 56, 91, 0.06);
      border-radius: 3px;
      border: 0px solid #eeeeee;
      .iconfont {
        padding: 0 30px;
      }
    }
    .f-between {
      .btn {
        width: 48%;
        // .iconfont {
        //   padding: 0 5px;
        // }
      }
    }
    .btn-DA6D58 {
      background: #da6d58;
    }
    .btn-a2a2a2 {
      background: #a2a2a2;
    }
    .btn-EC8A4B {
      background: #ec8a4b;
    }
    .btn-8CBD66 {
      background: #8cbd66;
    }
    .btn-60AAC4 {
      background: #60aac4;
    }
  }
}


.btns{
  box-shadow: 2px 4px 13px 0px rgba(42, 56, 91, 0.06);
  border-radius: 3px;
  border: 0px solid #eeeeee;
  padding: 10px 0;
  width: 140px;
  background-color: #1A70B8;
  .iconfont {
    padding: 0 10px;
  }
}
</style>
